import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FaTimes, FaBars } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import "../assets/css/navbar.css";
import "../assets/css/common.css";
import "../assets/css/responsive.css";

function Header(props) {
  const [click, setClick] = useState(false);
  const [headerFixed, setHeaderFixed] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [venturesDropdown, setVenturesDropdown] = useState(false);
  const [businessDropdown, setBusinessDropdown] = useState(false);
  const [aboutDropdown, setAboutDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  console.log("location", location);
  const vision = useRef();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setHeaderFixed(window.scrollY > 10);
    });
  }, []);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const onMouseEnter = () => {
    if (window.innerWidth <= 960) {
      setBusinessDropdown(false);
    } else {
      setBusinessDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth <= 960) {
      setBusinessDropdown(false);
    } else {
      setBusinessDropdown(false);
    }
  };

  const onMouseEnter1 = () => {
    if (window.innerWidth <= 960) {
      setVenturesDropdown(false);
    } else {
      setVenturesDropdown(true);
    }
  };

  const onMouseLeave1 = () => {
    if (window.innerWidth <= 960) {
      setVenturesDropdown(false);
    } else {
      setVenturesDropdown(false);
    }
  };

  const onMouseEnter2 = () => {
    if (window.innerWidth <= 960) {
      setAboutDropdown(false);
    } else {
      setAboutDropdown(true);
    }
  };

  const onMouseLeave2 = () => {
    if (window.innerWidth <= 960) {
      setAboutDropdown(false);
    } else {
      setAboutDropdown(false);
    }
  };

  const fetchUrl = (page_section) => {

    navigate(`/about/#${page_section}`);
    // console.log(page_section.current);
    // window.scrollTo({ behavior: 'smooth', top: page_section.current.offsetTop })
  };
  const ventureUrl = (page_section) => {
    navigate(`/ventures/#${page_section}`);
  };

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        window.scrollTo({
          top: document.querySelector(`${location.hash}`).offsetTop,
          behavior: "smooth",
          block: 'start' 
        });
      }, 500);
    }
  }, [location.hash]);

  return (
    <>
      <header className={headerFixed ? "header-fixed" : ""} id="headerPanel">
        {/* <div className="top-header text-right">
          <ul>
            <li>
              <Link to="/">Careers</Link>
            </li>
            <li>
              <Link to="/">Fast Facts</Link>
            </li>
            <li>
              <Link to="/">News</Link>
            </li>
          </ul>
        </div> */}
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes color="#fff" /> : <FaBars color="#7d2c29" />}
            </div>
            <div className="navbar-collapse collapse navbar-container">
              <div className="nav navbar-nav navbar-left">
                <Link className="navbar-brand" to="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                    alt="logo"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                    data-aos-offset="0"
                    className="img-fluid"
                  />
                  {/* Logo */}
                </Link>
              </div>
              <ul
                className={
                  click
                    ? "nav-menu active navbar-nav"
                    : "nav-menu navbar-nav navbar-center"
                }
              >
                <li className="nav-item">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive ? "current nav-link" : "nav-link"
                    }
                    onClick={closeMobileMenu}
                  >
                    Home
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  onMouseEnter={onMouseEnter2}
                  onMouseLeave={onMouseLeave2}
                  onClick={()=>setAboutDropdown(!aboutDropdown)}
                >
                  <NavLink
                    to="/about"
                    className={({ isActive }) =>
                      isActive
                        ? "current nav-link dropdown-toggle"
                        : "nav-link dropdown-toggle"
                    }
                    // onClick={closeMobileMenu}
                  >
                    About Us
                  </NavLink>
                  {aboutDropdown && (
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={(e) => {
                            e.preventDefault();
                            fetchUrl("our-approch");
                            closeMobileMenu()
                          }}
                        >
                          Our Approach
                        </a>
                      </li>
                 
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={(e) => {
                            e.preventDefault();
                            fetchUrl("vision");
                            closeMobileMenu()
                          }}
                        >
                          Mission & Vision 
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={(e) => {
                            e.preventDefault();
                            fetchUrl("why-anika");
                            closeMobileMenu()
                          }}
                        >
                          Why Anika
                        </a>
                        
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/leadership"
                          onClick={closeMobileMenu}
                        >
                         Leadership
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/our-story"
                    className={({ isActive }) =>
                      isActive ? "current nav-link" : "nav-link"
                    }
                    onClick={closeMobileMenu}
                  >
                    Our Story
                  </NavLink>
                </li>

                <li
                  className="nav-item"
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  onClick={()=>setBusinessDropdown(!businessDropdown)}
                >
                  <NavLink
                    to="/businesses"
                    className={({ isActive }) =>
                      isActive
                        ? "current nav-link dropdown-toggle"
                        : "nav-link dropdown-toggle disable-click"
                    }
                    onClick={closeMobileMenu}
                  >
                    Businesses
                  </NavLink>
                  {businessDropdown && (
                    <ul className="dropdown-menu">
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/paper"
                          onClick={closeMobileMenu}
                        >
                          Paper
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/pharmaceuticals"
                          onClick={closeMobileMenu}
                        >
                         Pharma
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/fertilisers"
                          onClick={closeMobileMenu}
                        >
                        Fertilizer
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/surface-protection-film"
                          onClick={closeMobileMenu}
                        >
                      Surface Protection Film
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/biodiesel"
                          onClick={closeMobileMenu}
                        >
                       Biodiesel
                        </NavLink>
                      </li>
                   
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/timber"
                          onClick={closeMobileMenu}
                        >
                       Timber
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/waste-paper-management"
                          onClick={closeMobileMenu}
                        >
                      Waste Paper Management
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/nbfc"
                          onClick={closeMobileMenu}
                        >
                       NBFC 
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>

                <li
                  className="nav-item"
                  onMouseEnter={onMouseEnter1}
                  onMouseLeave={onMouseLeave1}
                  onClick={()=>setVenturesDropdown(!venturesDropdown)}
                >
                  <NavLink
                    to="/ventures"
                    className={({ isActive }) =>
                      isActive
                        ? "current nav-link dropdown-toggle"
                        : "nav-link dropdown-toggle"
                    }
                    
                  >
                    Ventures
                  </NavLink>
                  {venturesDropdown && (
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={(e) => {
                            e.preventDefault();
                            ventureUrl("ecotec");
                            closeMobileMenu()
                          }}
                        >
                         Ecotec Developers 
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={(e) => {
                            e.preventDefault();
                            ventureUrl("ecotec");
                            closeMobileMenu()
                          }}
                        >
                       Supreme Realcon</a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={(e) => {
                            e.preventDefault();
                            ventureUrl("bb");
                            closeMobileMenu()
                          }}
                        >
                        BB Hospitality Ventures 
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={(e) => {
                            e.preventDefault();
                            ventureUrl("bb");
                            closeMobileMenu()
                          }}
                        >
                         Superfine Films
                        </a>
                      </li>
                      
                     
                    </ul>
                  )}
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/contact"
                    className={({ isActive }) =>
                      isActive ? "current nav-link" : "nav-link"
                    }
                    onClick={closeMobileMenu}
                  >
                    Contact Us
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                            <NavLink className="nav-link get-quote">Schedule A Visit</NavLink>
                        </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
