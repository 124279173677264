import React from "react";
import { Audio } from "react-loader-spinner";

const Preloader = () => {

  return (
    <div
      className="page-wrapper"
      style={{
        minHeight: "600px",
        backgroundColor: "#7d2c29",
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center"
      }}
    >
      <Audio
        type="Oval"
        color="#FFF"
        height={40}
        width={40}
        ariaLabel="loading"
      />
    </div>
  );
};

export default Preloader;
