
const contactFormApi =`https://www.anika.in/mails/contact.php`;
const blogApi =`https://www.anika.in/admin/blog`;
const blogDetailsApi =`https://www.anika.in/admin/blog/`;
const testimonialApi =`https://www.anika.in/admin/testimonial/`;
const testimonialDetailsApi =`https://www.anika.in/admin/testimonial/:id`;
const metaPageApi =`https://www.anika.in/admin/pages/`;

export const ApiUrl = {
    contactFormApi, blogApi, blogDetailsApi, testimonialApi, testimonialDetailsApi, metaPageApi
  };
export const BaseURL = "https://www.anika.in";
export const SOCIAL_LINK = {
    FACEBOOK: "https://www.facebook.com/",
    INSTAGRAM: "https://instagram.com/",
    YOUTUBE: "https://youtube.com/",
    LINKEDIN: "https://www.linkedin.com/",
    TWITTER:"https://twitter.com/",
};
