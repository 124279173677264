import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "./assets/css/Preloader.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Preloader from "./components/preloader";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SmoothScroll from "./components/SmoothScroll";
import ScrollIndicator from "./components/scrollIndicator/ScrollIndicator";


const Home = lazy(() => import("./pages/home"));
const About = lazy(() => import("./pages/about"));
const History = lazy(() => import("./pages/history"));
const BlogDetails = lazy(() => import("./pages/blogs/BlogDetails"));
const Ventures = lazy(() => import("./pages/ventures"));
const Pharma = lazy(() => import("./pages/businesses/Pharma"));
const BioDiesel = lazy(() => import("./pages/businesses/BioDiesel"));
const WastePaper = lazy(() => import("./pages/businesses/WastePaper"));

const EcotecDevelopers = lazy(() =>
  import("./pages/ventures/Components/ecotecDevelopers")
);
const BBHospitalityVentures = lazy(() =>
  import("./pages/ventures/Components/HospitalityVentures")
);
const SuperfineFilms = lazy(() =>
  import("./pages/ventures/Components/SuperfineFilms")
);
const SupremeRealcom = lazy(() =>
  import("./pages/ventures/Components/SupremeRealcom")
);
const Business = lazy(() => import("./pages/businesses"));
const Fertilisers = lazy(() => import("./pages/businesses/Fertilisers"));
const Timber = lazy(() => import("./pages/businesses/Timber"));
const SurfaceProtectionAnika = lazy(() =>
  import("./pages/businesses/SurfaceProtectionAnika")
);
const VigFinance = lazy(() => import("./pages/businesses/VigFinance"));
const OurClients = lazy(() => import("./pages/clients/"));
const LeaderShip = lazy(() => import("./pages/leaderShip"));
const WhyAnika = lazy(() => import("./pages/whyAnika"));
const Blog = lazy(() => import("./pages/blogs"));
const Contact = lazy(() => import("./pages/contact"));
const ThankYou = lazy(() => import("./pages/ThankYou"));
const NotFound = lazy(() => import("./pages/NotFound"));

function App(props) {
  const [mobileScreen, setMobileScreen] = useState(false);

  useEffect(() => {
    console.log("window", window.innerWidth);
    if (window.innerWidth <= 500) {
      setMobileScreen(true);
    } else {
      setMobileScreen(false);
    }
  }, []);
  return (
    <BrowserRouter basename="/">
      <ScrollIndicator />
      {/* <SmoothScroll /> */}
      <Header />

      <Suspense fallback={Preloader()}>
        <Routes>
          <Route
            exact
            path="/"
            element={<Home mobileScreen={mobileScreen} title="Anika" slug="home-page" />}
          />
          <Route
            exact
            path="/about"
            element={
              <About mobileScreen={mobileScreen} title="About | Anika" slug="about" />
            }
          />
          <Route
            exact
            path="/our-story"
            element={<History mobileScreen={mobileScreen} title="History | Anika" slug="our-story" />}
          />

          <Route
            exact
            path="/ventures"
            element={<Ventures title="Ventures | Anika" slug="ventures" />}
          />
          <Route
            exact
            path="/ecotec-developers"
            element={
              <EcotecDevelopers title="Ecotec Developers  | Anika" />
            }
          />
          <Route
            exact
            path="/bb-hospitality-ventures"
            element={
              <BBHospitalityVentures title="BB Hospitality Ventures  | Anika" />
            }
          />
          <Route
            exact
            path="/superfine-films"
            element={<SuperfineFilms title="Superfine Films  | Anika" />}
          />
          <Route
            exact
            path="/supreme-realcom"
            element={<SupremeRealcom title="Supreme Realcom  | Anika" />}
          />
          <Route
            exact
            path="/paper"
            element={
              <Business
                mobileScreen={mobileScreen}
                title="Businesses | Anika"
                slug="paper"
              />
            }
          />
          <Route
            exact
            path="/fertilisers"
            element={
              <Fertilisers
                mobileScreen={mobileScreen}
                title="Fertilisers | Anika"
                slug="fertilisers"
              />
            }
          />
          <Route
            exact
            path="/timber"
            element={
              <Timber
                mobileScreen={mobileScreen}
                title="Timber | Anika"
                slug="timber"
              />
            }
          />
          <Route
            exact
            path="/surface-protection-film"
            element={
              <SurfaceProtectionAnika
                mobileScreen={mobileScreen}
                title="Surface Protection Anika | Anika"
                slug="surface-protection-film"
              />
            }
          />
          <Route
            exact
            path="/nbfc"
            element={
              <VigFinance
                mobileScreen={mobileScreen}
                title="VigFinance | Anika"
                slug="nbfc"
              />
            }
          />
          <Route
            exact
            path="/pharmaceuticals"
            element={
              <Pharma
                mobileScreen={mobileScreen}
                title="Pharma | Anika"
                slug="pharmaceuticals"
              />
            }
          />
            <Route
            exact
            path="/biodiesel"
            element={
              <BioDiesel
                mobileScreen={mobileScreen}
                title="Biodiesel | Anika"
                slug="biodiesel"
              />
            }
          />
          <Route
            exact
            path="/waste-paper-management"
            element={
              <WastePaper
                mobileScreen={mobileScreen}
                title="Waste Paper | Anika"
                slug="waste-paper"
              />
            }
          />
          <Route
            exact
            path="/our-clients"
            element={
              <OurClients
                mobileScreen={mobileScreen}
                title="Our Clients | Anika"
              />
            }
          />
          <Route
            exact
            path="/leadership"
            element={<LeaderShip title="Leadership | Anika" slug="leadership" />}
          />
          <Route
            exact
            path="/why-anika"
            element={<WhyAnika title="why anika | Anika" slug="why-anika" />}
          />

          <Route
            exact
            path="/blogs"
            element={
              <Blog mobileScreen={mobileScreen} title="Blog | Anika" slug="blogs" />
            }
          />
          <Route
            exact
            path="/blog/:pathname"
            element={
              <BlogDetails
                mobileScreen={mobileScreen}
                title="Blog Details | Anika"
              />
            }
          />
          <Route
            exact
            path="/contact"
            element={
              <Contact
                mobileScreen={mobileScreen}
                title="Contact | Anika"
                slug="contact"
              />
            }
          />

          <Route
            exact
            path="/thank-you"
            element={<ThankYou title="Thank You | Anika"
            slug="thank-you" />}
          />

          <Route path="*" element={<NotFound title="404 | Anika" />} />
        </Routes>
      </Suspense>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
